import LoginIcon from "@mui/icons-material/Login";
import Button from "@mui/material/Button";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { SetLoadingOverlay, SetRole } from "../Redux/Action";
import { connect } from "react-redux";
import Keybow from "../Assets/M4DBKEagleLargeBow.png";
import { useEffect, useState } from "react";
import { viewErrorMessage } from "../CommonFunctions/CommonMethods";
import { MenuItem, Select } from "@mui/material";
import { fields } from "./CommonConstants";
import { createNewUser, getUserTypes, verifyRecaptcha } from "../CommonFunctions/ApiCalls";
import { toast } from "react-toastify";
import CommonModel from "./CommonModel";

const Login = (props) => {
  const [registerNewUser, setregisterNewUser] = useState({});
  const [errormsg, setErrormsg] = useState([]);
  const [userType, setUserType] = useState([]);
  const [isDisable, setIsDisable] = useState(true);
  // const [isOpen, setIsOpen] = useState(false);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const SITE_KEY = process.env.REACT_APP_SITE_KEY;
//force change for pull request
    useEffect(() => {
      userTypeApi();      
    }, []);

    //Load the google recaptcha script and generate token when user opens the register popup
    const loadRecaptchaScript = async () => {
      // Load Google reCAPTCHA script
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=` + SITE_KEY; // Replace with your Site Key
      script.async = true;
      document.body.appendChild(script);

      script.onload = () => {
        // Generate reCAPTCHA v3 token
        window.grecaptcha.ready(() => {
          window.grecaptcha
            .execute(SITE_KEY, { action: "submit" }) // Replace with your Site Key
            .then((token) => {
              console.log("reCAPTCHA Token:", token);
              setRecaptchaToken(token); // Save the token
            });
        });
      };
      return () => {
        document.body.removeChild(script);
      };
    };

  useEffect(() => {
    let hasValue = true;
    fields.register.forEach((element) => {
      if (!registerNewUser[Object.keys(element)[0]]) {
        hasValue = false;
        return;
      }
    });
    setIsDisable(!(hasValue && errormsg.length === 0));
  }, [registerNewUser]);

  const userTypeApi = async () => {
    await getUserTypes()
      .then((data) => {
        if (data.items && data.items.length > 0) {
          let tempData = [...data.items];
          tempData = tempData.filter((each) => each.registrationAllowed);
          setUserType(tempData);
        }
      })
      .catch((e) => console.log(e, "erro"));
  };

  const getInputType = (fieldkey, type, dropDown = null) => {
    switch (type) {
      case "type0":
        return (
          <>
            <input
              type="text"
              className={
                "border border-gray-400 focus:outline-none focus:ring-[#968049] active:ring-inset focus:ring-1 rounded-lg p-2.5 h-[25px] w-64 text-size"
              }
              onChange={(e) => handleOnChange(fieldkey, e.target.value)}
              value={registerNewUser && registerNewUser[fieldkey]}
            />
            <div className="text-xs text-red-500 w-full">
              {viewErrorMessage(fieldkey, errormsg)}
            </div>
          </>
        );
      case "type1":
        return (
          <>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              sx={{ fontSize: "small" }}
              className={
                "border-0 text-gray-900 text-xs rounded-lg h-[1.6rem] m-0 w-64"
              }
              value={
                registerNewUser && registerNewUser[fieldkey]
                  ? registerNewUser[fieldkey]
                  : "0"
              }
              onChange={(e) => {
                handleOnChange(fieldkey, e.target.value);
              }}
            >
              <MenuItem value="0" sx={{ fontSize: "small" }}>
                Please Select...
              </MenuItem>
              {dropDown.options &&
                dropDown.options.length > 0 &&
                dropDown.options.map((data) => {
                  console.log(dropDown.options, "dropdown");
                  return (
                    <MenuItem
                      value={data[dropDown.id]}
                      sx={{ fontSize: "small" }}
                    >
                      {data[dropDown.name]}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className="text-xs text-red-500 w-full">
              {viewErrorMessage(fieldkey, errormsg)?.replace("Enter", "Select")}
            </div>
          </>
        );
    }
  };

  const handleOnChange = (fieldName, value) => {
    console.log(fieldName, value, "onChange");
    let updateRegister = { ...registerNewUser };
    updateRegister[fieldName] = value;
    setregisterNewUser(updateRegister);
    if (
      fields.register.some((obj) => obj.hasOwnProperty(fieldName)) ||
      fieldName === "phoneNumber"
    ) {
      let error = handleFormValidation(fieldName, value, updateRegister);
      setErrormsg(error);
    }
  };

  const handleFormValidation = (fieldName, value) => {
    let validationFaildMessage =
      errormsg && errormsg.length > 0 ? [...errormsg] : [];
    if (!value) {
      if (fieldName === "phoneNumber") {
        if (
          validationFaildMessage[
            validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
          ]
        ) {
          validationFaildMessage = validationFaildMessage.filter(
            (error) => error.fieldName !== fieldName
          );
        }
      } else if (fieldName === "emailAddress") {
        if (
          validationFaildMessage[
            validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
          ]
        ) {
          validationFaildMessage[
            validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
          ] = {
            fieldName: fieldName,
            errorMessage: `Please Enter ${
              fields.register.find((each) => each[fieldName])[fieldName]
            }`,
          };
        } else {
          validationFaildMessage.push({
            fieldName: fieldName,
            errorMessage: `Please Enter ${
              fields.register.find((each) => each[fieldName])[fieldName]
            }`,
          });
        }
      } else {
        validationFaildMessage.push({
          fieldName: fieldName,
          errorMessage: `Please Enter ${
            fields.register.find((each) => each[fieldName])[fieldName]
          }`,
        });
      }
    } else {
      if (value && value !== "0") {
        validationFaildMessage = validationFaildMessage.filter(
          (error) => error.fieldName !== fieldName
        );
        if (fieldName === "emailAddress" && !/\S+@\S+\.\S+/.test(value)) {
          validationFaildMessage.push({
            fieldName: fieldName,
            errorMessage: `Please Enter valid ${
              fields.register.find((each) => each[fieldName])[fieldName]
            }`,
          });
        }
        if (fieldName === "phoneNumber" && !/^(\d+-?)+\d+$/.test(value)) {
          validationFaildMessage.push({
            fieldName: fieldName,
            errorMessage: "Please Enter valid Phone Number",
          });
        }
      } else {
        if (value === "0") {
          validationFaildMessage.push({
            fieldName: fieldName,
            errorMessage: `Please Select ${
              fields.register.find((each) => each[fieldName])[fieldName]
            }`,
          });
        } else {
          if (
            validationFaildMessage[
              validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
            ]
          ) {
            validationFaildMessage[
              validationFaildMessage.findIndex((a) => a.fieldName === fieldName)
            ] = {
              fieldName: fieldName,
              errorMessage: `Please Enter ${
                fields.register.find((each) => each[fieldName])[fieldName]
              }`,
            };
          } else {
            validationFaildMessage.push({
              fieldName: fieldName,
              errorMessage: `Please Enter ${
                fields.register.find((each) => each[fieldName])[fieldName]
              }`,
            });
          }
        }
      }
    }
    return validationFaildMessage;
  };

  const handleCreateRegister = async () => {
    props.SetLoadingOverlay(true);
    // Send recaptchaToken to your backend for validation
    var success = await verifyRecaptcha(recaptchaToken);
    if(success){
      let updateRegister = { ...registerNewUser };
      if (!registerNewUser?.consentToMarketingEmails) {
        updateRegister["consentToMarketingEmails"] = false;
      }
      await createNewUser(updateRegister)
        .then((result) => {
          if (result && result.statusCode === 200) {
            toast.success("User Added Successfully !!!");
            handleClose();
          } else {
            toast.error("An Error Occured !!!");
            handleClose();
          }
        })
        .catch((e) => toast.error("An Error Occured !!!"));
      }
      else {
        toast.error("reCAPTCHA validation failed. Please try again.");
        handleClose();
      }
    props.SetLoadingOverlay(false);
    
  };

  const getModelView = () => {
    return (
      <div className="flex flex-col items-center justify-center bg-white overflow-auto">
        <div className="text-xl font-bold m-1">Register</div>
        <div className="grid grid-cols-2 gap-10 m-5">
          <div className="grid grid-cols-3 gap-3">
            <label>
              User Type<span className="text-red-500">*</span>{" "}
            </label>
            <div className="col-span-2">
              {getInputType("userType", "type1", {
                options: userType,
                id: "value",
                name: "key",
              })}
            </div>
            <label>Title</label>
            <div className="col-span-2">{getInputType("title", "type0")}</div>
            <label>
              First Name<span className="text-red-500">*</span>{" "}
            </label>
            <div className="col-span-2">
              {getInputType("firstName", "type0")}
            </div>
            <label>
              Last Name<span className="text-red-500">*</span>{" "}
            </label>
            <div className="col-span-2">
              {getInputType("lastName", "type0")}
            </div>
            <label>
              Email Address<span className="text-red-500">*</span>{" "}
            </label>
            <div className="col-span-2">
              {getInputType("emailAddress", "type0")}
            </div>
            <label>Phone Number</label>
            <div className="col-span-2">
              {getInputType("phoneNumber", "type0")}
            </div>
          </div>
          <div className="grid grid-cols-3 gap-3">
            <label>
              Company Name<span className="text-red-500">*</span>
            </label>
            <div className="col-span-2">
              {getInputType("companyName", "type0")}
            </div>
            <label>Address</label>
            <div className="col-span-2">{getInputType("address", "type0")}</div>
            <label>Address 2</label>
            <div className="col-span-2">
              {getInputType("address2", "type0")}
            </div>
            <label>City</label>
            <div className="col-span-2">{getInputType("city", "type0")}</div>
            <label>State</label>
            <div className="col-span-2">{getInputType("state", "type0")}</div>
            <label>Postal Code</label>
            <div className="col-span-2">
              {getInputType("postalCode", "type0")}
            </div>
          </div>
          <div className="col-span-3">
            <input
              type="checkbox"
              checked={
                registerNewUser && registerNewUser.consentToMarketingEmails
              }
              onChange={(e) =>
                handleOnChange("consentToMarketingEmails", e.target.checked)
              }
            />{" "}
            Interested in receiving news and product updates from Medeco? Please
            check the box to opt into emails.
          </div>
        </div>
      </div>
    );
  };

  const handleClose = () => {
    props.setOpenRegistration(false);
    setErrormsg([]);
    setregisterNewUser({});
  };

  return (
    <>
      <div className="top-[117px] w-full h-full flex flex-row">
        <div className="flex w-1/3 justify-center items-center flex-col gap-3 bg-[#644876e6] m-4 rounded-lg p-4">
          <p className="text-white text-2xl font-semibold 2xl:text-4xl">
            Login
          </p>
          <AccountCircleIcon style={{ width: "120px", height: "120px" }} />
          <Button
            variant="contained"
            endIcon={<LoginIcon />}
            className="w-64 h-10 "
            style={{
              borderRadius: "10px",
              boxShadow: "rgb(143 143 143) 3px 4px 2px 0px",
              backgroundColor: "#968049",
            }}
            onClick={props.handleLogin}
          >
            Login
          </Button>
          <p className="text-white mt-6 text-lg">
            New here?{" "}
            {/* <span
                className="text-base hover:text-[#000080] hover:cursor-pointer hover:underline"
                onClick={() => setIsRegisterClicked(true)}
              >
                Click here to REGISTER
              </span> */}
          </p>
          <Button
            variant="contained"
            className="w-64 h-10"
            style={{
              borderRadius: "10px",
              boxShadow: "rgb(143 143 143) 3px 4px 2px 0px",
              backgroundColor: "#ffffff",
              color: "black",
              border: "1px solid black",
            }}
            onClick={() => { props.setOpenRegistration(true); loadRecaptchaScript(); }}
          >
            Click Here TO REGISTER
          </Button>
        </div>

        <div className="bg-black m-5 rounded-lg overflow-auto w-2/3">
          <p className="font-bold m-5 text-4xl text-white 2xl:text-5xl">
            Welcome to Medeco Fusion!
          </p>
          <div className="mx-10">
            <div className="float-left text-sm text-white text-justify 2xl:text-lg">
              <img
                className="float-right w-36 mx-3 2xl:w-56"
                src={Keybow}
                style={{
                  transform: "rotate(15deg)",
                }}
              />
              <ul>
                <li className="mb-8">
                  &emsp;&emsp;Onboard with Medeco Fusion, a comprehensive and
                  centralized digital repository for all our customer-facing
                  tools. This innovative platform is designed to streamline and
                  simplify the onboarding process, featuring digital agreements
                  and coining die design capabilities. By leveraging advanced
                  digital solutions, we ensure the utmost precision in
                  customer-provided data at the factory, guaranteeing
                  high-quality outcomes for our clients.
                </li>

                <li>&emsp;&emsp;What you can do?</li>
                <li>&emsp;&emsp;&emsp;&emsp;Digitally Sign Agreements</li>
                <li>&emsp;&emsp;&emsp;&emsp;Design Coining Dies</li>
                <li>&emsp;&emsp;&emsp;&emsp;Design Your Keysystem</li>

                <li className="mt-8">
                  &emsp;&emsp;Moreover, Medeco Fusion significantly improves
                  overall efficiency and reduces time consumption for both our
                  customers and our team. By consolidating all essential tools
                  into one accessible location, we eliminate the need for
                  time-consuming manual processes and multiple points of
                  contact.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <CommonModel
        isOpen={props.openRegistration}
        onCloseModel={() => handleClose()}
        header="Register"
        body={getModelView()}
        size="950px"
        onSubmit={() => handleCreateRegister()}
        footer={{
          isVisible: true,
          isSubmitVisible: true,
          iscancelVisible: true,
          isSubmitDisable: isDisable,
        }}
        buttonName={{
          submit: "Register",
          cancel: "Cancel",
        }}
        height="65%"
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    SetRole: (isLogin, role) => dispatch(SetRole(isLogin, role)),
    SetLoadingOverlay: (isLoading) => dispatch(SetLoadingOverlay(isLoading)),
  };
};

export default connect(null, mapDispatchToProps)(Login);
